<template>
  <default-layout>
    <template #content>
      <h1 class="text-2xl text-center mt-16">Forget your password?</h1>
      <p class="text-center mt-8">
        Please enter your account
        <br />
        email address and we'll send the instructions
        <br />
        to that email to reset your password.
      </p>
      <p class="text-center mt-8">
        You may need to check
        <br />
        your spam/junk folder.
      </p>
      <transition name="fade">
        <p class="bg-red-100 p-5 my-5 border border-red-200 rounded text-red-500" v-if="error">
          {{ error }}
        </p>
      </transition>
      <form @submit.prevent="validateBeforeSubmit" class="mx-8">
        <div class="mb-4 mx-8 border-b-2 border-primary focus-within:border-primary">
          <label for="email" class="font-semibold text-gray-700 text-center hidden">Email</label>
          <form-input
            v-model="email"
            v-validate="'required|email'"
            type="email"
            placeholder="Email"
            id="email"
            name="email"
            class="form-element"
          />
        </div>
        <span v-show="errors.has('email')" class="text-xs text-red-500 mx-8">{{ errors.first("email") }}</span>
        <button type="submit" class="form-button form-button--full mb-6 uppercase mt-20">send</button>
        <nav-button to="/" outline>Back</nav-button>
      </form>
    </template>
  </default-layout>
</template>

<script>
import { FormInput, NavButton } from "@/components";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { mapGetters } from "vuex";

// todo: Replace URL with env variable
export default {
  name: "PasswordReset",
  components: { DefaultLayout, FormInput, NavButton },
  data: () => ({
    email: ""
  }),
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.sendEmail();
          return;
        }
        console.log("The form is not submitted. Correct the errors");
      });
    },

    sendEmail() {
      const actionCodeSettings = {
        url: "https://ex-spore.com/signin",
        handleCodeInApp: false
      };

      this.$store
        .dispatch("auth/resetPassword", {
          email: this.email,
          actionCodeSettings: actionCodeSettings
        })
        .then(() => {
          this.$router.push("/");
          this.$toast.success("Please, check your email to reset your password");
        });
    }
  },
  computed: {
    ...mapGetters("auth", ["error"])
  }
};
</script>
